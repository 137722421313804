<template>
  <footer class="contentinfo" role="contentinfo">
    <div class="wrapper">
      <p class="copyright">{{ year }} &copy; Copyright {{ siteName }} <a href="mailto:info@societytoronto.com">info@societytoronto.com</a></p>

      <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      siteName: 'Business Society Toronto'
    }
  }
}
</script>

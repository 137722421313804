<template>
  <div>

    <div v-if="error" class="error" v-html="error"></div>

    <div class="wrapper" v-if="member">
      <form @submit.prevent="updateUser" class="form">
        <p><router-link to="/change-password">Change Your Password</router-link></p>

        <h1>Edit Business Society Toronto Profile</h1>

        <h2>Member Information</h2>

        <FormGroup label="Name" v-model="member.name" :errors="errors.name" @errors="updateErrors" :required="true" />

        <fieldset class="columns">

          <FormGroup label="Company" v-model="member.acf.company" :errors="errors.company" @errors="updateErrors" />

          <FormGroup label="Company URL" type="url" v-model="member.acf.company_url" :errors="errors.company_url" @errors="updateErrors"/>
        </fieldset>

        <fieldset class="columns">
          <FormGroup label="Email Address" v-model="member.meta.email" :readonly="true" />

          <FormGroup label="Phone" type="tel" v-model="member.acf.telephone" :errors="errors.phone" @errors="updateErrors"/>
        </fieldset>

        <div class="form-group form-group--image" :class="{ 'form-group--error': errors.member_image }">

          <label for="member_image">Member Image</label>

          <span :class="{ 'loading': member_image_loading }" v-if="member_image !== 'remove'">
            <template v-if="member_image_loading">
              <span class="spinner"></span>
              <p class="sr-only">Loading...</p>
            </template>
            <img v-if="member_image || member.acf_json.member_image" :src="member_image ? member_image_url : member.acf_json.member_image.url" />
          </span>
          <input type="file" id="member_image" @change="addImage($event, 'member_image')" />

          <div v-if="errors.member_image" class="error-message">{{ errors.member_image }}</div>

          <button type="button" @click="removeImage('member_image')" v-if="(member_image && member_image !== 'remove') || member.acf.member_image" class="btn min">
            <FontAwesomeIcon :icon="icon.minus" />
            Remove Image
          </button>
        </div>

        <h2>Social profiles</h2>

        <fieldset class="columns" v-for="n of [1,2,3,4]" v-bind:key="n">
          <FormGroup :fieldName="'social_media__' + n" type="select" v-model="member.acf['social_' + n].social_media" :errors="errors['social_media__' + n]" @errors="updateErrors" :options="social_media" :readonly="true" />
          <FormGroup :fieldName="'profile_url_' + n" type="url" v-model="member.acf['social_' + n].profile_url" :errors="errors['profile_url_' + n]" @errors="updateErrors" />
        </fieldset>

        <h2>Personal Interests</h2>

        <FormGroup label="Personal Interests" type="textarea" v-model="member.acf.personal_interests" :errors="errors.personal_interests" @errors="updateErrors" />

        <div class="form-group form-group--image" :class="{ 'form-group--error': errors.personal_image }">

          <label for="personal_image">Personal Image</label>

          <span :class="{ 'loading': personal_image_loading }" v-if="personal_image !== 'remove'">
            <template v-if="personal_image_loading">
              <span class="spinner"></span>
              <p class="sr-only">Loading...</p>
            </template>
            <img v-if="personal_image || member.acf_json.personal_image" :src="personal_image ? personal_image_url : member.acf_json.personal_image.url" />
          </span>
          <input type="file" id="personal_image" @change="addImage($event, 'personal_image')" />

          <div v-if="errors.personal_image" class="error-message">{{ errors.personal_image }}</div>

          <button type="button" @click="removeImage('personal_image')" v-if="(personal_image && personal_image !== 'remove') || member.acf.personal_image" class="btn min">
            <FontAwesomeIcon :icon="icon.minus" />
            Remove Image
          </button>
        </div>

        <h2>Business Information</h2>

        <FormGroup label="Business Information" type="textarea" v-model="member.acf.business_information" :errors="errors.business_information" @errors="updateErrors" />

        <h3>Other Businesses / Investments</h3>

        <fieldset class="repeater" v-for="(member_business, index) in member.acf.businesses" v-bind:key="`member-business-${index}`">

          <fieldset class="columns">
            <FormGroup label="Business Name" :fieldName="'business_name_' + index" v-model="member_business.name" :errors="errors['business_name_' + index]" @errors="updateErrors" />

            <FormGroup label="Business URL" type="url" :fieldName="'business_url_' + index" v-model="member_business.url" :errors="errors['business_url_' + index]" @errors="updateErrors" />
          </fieldset>

          <fieldset class="columns">
            <FormGroup label="Short Description" :fieldName="'business_description_' + index" v-model="member_business.description" :errors="errors['business_description_' + index]" @errors="updateErrors" />

            <FormGroup label="Year Started" type="select" :fieldName="'business_year_started_' + index" v-model="member_business.year" :errors="errors['business_year_started_' + index]" @errors="updateErrors" :options="years" />
          </fieldset>

          <button type="button" @click="member.acf.businesses.splice(index, 1)" v-if="index > 0" class="btn min repeater-item-remove">
            <FontAwesomeIcon :icon="icon.minus" />
            Remove
          </button>
        </fieldset>

        <button type="button" @click="member.acf.businesses.push({})" class="btn min repeater-item-add">
          <FontAwesomeIcon :icon="icon.plus" />
          Add Business
        </button>

        <div class="form-footer">
          <button type="submit" class="btn lrg">Update Profile</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';

export default {
  name: 'EditMemberProfile',
  components: {
    FormGroup
  },
  mounted() {
    this.$store.dispatch('getMember', this.$route.params.slug).then(() => {
      this.member = structuredClone(this.$store.getters.getMember(this.$route.params.slug))

      if (!this.member) {
        this.$router.push('/members')
        return;
      }

      if (this.user && !this.user.roles.includes('administrator') && (!this.user.roles.includes('member') || this.user.slug !== this.$route.params.slug)) {
        this.$router.push('/members/' + this.member.slug)
      }

      if (!this.member.acf.businesses || this.member.acf.businesses.length === 0) {
        this.member.acf.businesses = [{}]
      }

      this.member_image_id = this.member.acf.member_image
      this.initialMemberName = this.member.name
    })
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    user() {
      return this.$store.getters.getUser
    },
    member_image_url() {
      if (this.member_image === 'remove')
        return null
      return URL.createObjectURL(this.member_image)
    },
    personal_image_url() {
      if (this.personal_image === 'remove')
        return null
      return URL.createObjectURL(this.personal_image)
    },
    social_media() {
      return this.$store.getters.getSocialMedia
    },
    years() {
      const currentYear = new Date().getFullYear()
      return Array.from({length: 100}, (v, k) => currentYear - k).map(year => {
        return { value: year, name: year }
      })
    }
  },
  data() {
    return {
      icon: {
        plus: faPlus,
        minus: faMinus
      },
      initialMemberName: '',
      member: false,
      member_image: false,
      member_image_loading: false,
      member_image_id: null,
      personal_image: false,
      personal_image_loading: false,
      personal_image_id: null,
      errors: {}
    }
  },
  methods: {
    addImage(ev, id) {
      ev.preventDefault()
      delete this.errors[id]

      const file = ev.target.files[0]
      const maxSize = 2 * 1024 * 1024 // 4MB
      const allowedFormats = ['image/jpeg', 'image/png', 'image/gif']

      if (file.size > maxSize) {
        this.errors[id] = 'File size exceeds the maximum limit of 2MB.'
        console.log(file.size, id, this.errors)
        this.$forceUpdate()
        return
      }

      if (!allowedFormats.includes(file.type)) {
        this.errors[id] = 'Invalid file format. Only JPEG, PNG, and GIF images are allowed.'
        console.log(file.type, id, this.errors)
        this.$forceUpdate()
        return
      }

      this[id] = file
      this[id + '_loading'] = true
      this.$store.dispatch('addMedia', this[id]).then((attachment) => {
        if (!attachment) {
          this.errors[id] = 'Error uploading image. Please try again.'
          this[id + '_loading'] = false
          return
        }
        this[id + '_loading'] = false
        this[id + '_id'] = attachment.id
      }).catch((error) => {
        this.errors[id] = error
        this[id + '_loading'] = false
      })
    },
    updateUser() {
      this.$store.commit('setError', false)

      if (Object.keys(this.errors).length > 0) {
        this.$store.commit('setError', 'Please correct errors before submitting')
        return
      }

      if (this.member_image_loading || this.personal_image_loading === null) {
        this.$store.commit('setError', 'Please wait for the image(s) to finish uploading')
        return
      }

      const member = {
        id: this.member.id,
        name: DOMPurify.sanitize(this.member.name),
        acf: {
          company: DOMPurify.sanitize(this.member.acf.company),
          company_url: DOMPurify.sanitize(this.member.acf.company_url),
          telephone: DOMPurify.sanitize(this.member.acf.telephone),
          social_1: {
            social_media: DOMPurify.sanitize(this.member.acf.social_1.social_media),
            profile_url: DOMPurify.sanitize(this.member.acf.social_1.profile_url)
          },
          social_2: {
            social_media: DOMPurify.sanitize(this.member.acf.social_2.social_media),
            profile_url: DOMPurify.sanitize(this.member.acf.social_2.profile_url)
          },
          social_3: {
            social_media: DOMPurify.sanitize(this.member.acf.social_3.social_media),
            profile_url: DOMPurify.sanitize(this.member.acf.social_3.profile_url)
          },
          social_4: {
            social_media: DOMPurify.sanitize(this.member.acf.social_4.social_media),
            profile_url: DOMPurify.sanitize(this.member.acf.social_4.profile_url)
          },
          personal_interests: DOMPurify.sanitize(this.member.acf.personal_interests),
          business_information: DOMPurify.sanitize(this.member.acf.business_information),
          businesses: this.member.acf.businesses.map(business => {
            const b = {
              name: DOMPurify.sanitize(business.name),
              url: DOMPurify.sanitize(business.url),
              description: DOMPurify.sanitize(business.description)
            }
            if (business.year) {
              b.year = DOMPurify.sanitize(business.year)
            }
            return b
          })
        }
      }

      if (this.member_image == 'remove') {
        member.acf.member_image = null
      } else if (this.member_image) {
        member.acf.member_image = this.member_image_id
      }

      if (this.personal_image == 'remove') {
        member.acf.personal_image = null
      } else if (this.personal_image) {
        member.acf.personal_image = this.personal_image_id
      }

      this.$store.dispatch('updateUser', member).then(() => {
        if (!this.error) {
          this.member = this.$store.getters.getMemberById(this.member.id)
          this.$router.push('/members/' + this.member.slug).then(() => {
            if (this.initialMemberName !== this.member.name && this.user.id === this.member.id) {
              this.$store.commit('login', this.member)
            }
          })
        }
      }).catch((error) => {
        this.$store.commit('setError', error)
      });
    },
    updateErrors(errors) {
      if (errors[1] === false) {
        delete this.errors[errors[0]]
      } else {
        this.errors[errors[0]] = errors[1]
      }
    },
    removeImage(id) {
      if (confirm("Are you sure you want to remove the image?")) {
        this.member.acf[id] = null
        this.member.acf_json[id] = null
        this[id] = 'remove'
        this[id + '_id'] = null
      }
    }
  }
}
</script>
